import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import orderListExport from './orderListExport'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.order',
      search: 'order.search_keyword',
      exportExcel: orderListExport(this.vm),
      filter: {
        order_status: {
          label: 'order.data.status',
          type: 'selection',
          // @ts-ignore
          options: () => this.vm.$orderTypes.map(type => ({
            text: this.vm?.$t(`order.data.status.${type}`),
            value: type,
          })),
        },
        payment_status: {
          label: 'ec.data.payment_status',
          type: 'selection',
          // @ts-ignore
          options: () => this.vm.$paymentStatus.map(type => ({
            text: this.vm?.$t(`ec.data.payment_status.${type}`),
            value: type,
          })),
        },
        delivery_status: {
          label: 'ec.data.delivery_status',
          type: 'selection',
          // @ts-ignore
          options: () => this.vm.$deliveryStatus.map(type => ({
            text: this.vm?.$t(`ec.data.delivery_status.${type}`),
            value: type,
          })),
        },
        payment_type: {
          label: 'ec.data.payment_type',
          type: 'selection',
          // @ts-ignore
          options: () => this.vm.allPaymentTypes.map(type => ({
            text: this.vm?.$t(`ec.data.payment_type.${type}`),
            value: type,
          })),
        },
        delivery_type: {
          label: 'ec.data.delivery_type',
          type: 'selection',
          // @ts-ignore
          options: () => this.vm.allDeliveryTypes.map(type => ({
            text: this.vm?.$t(`ec.data.delivery_type.${type}`),
            value: type,
          })),
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
      }),
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'order.data.amount', key: 'amount' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row.avatar,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'id',
            copyable: true,
            label: 'order.data.id',
            type: 'text',
          },
          {
            key: 'buyer',
            label: 'order.data.buyer',
            component: () => import('modules/order/views/orderList/buyer.vue'),
          },
          {
            key: 'amount',
            label: 'order.data.amount',
            type: 'text',
          },
          {
            key: 'amount_payable',
            label: 'order.data.amount_payable',
            type: 'text',
            help: 'order.data.amount_payable.help',
          },
          {
            key: 'status',
            label: 'order.data.status',
            type: 'text',
            text: row => this.vm?.$t(`order.data.status.${row.status}`),
            dataItemProperties: (row: AnyObject) => {
              let warning = false

              // @ts-ignore
              if(row.status === this.vm.$orderConstants.STATUS_CANCEL_REQUEST) warning = true

              // @ts-ignore
              if(row.status === this.vm.$orderConstants.STATUS_RETURN_REQUEST) warning = true

              if(!warning) return null
              return {
                class: 'orange--text'
              }
            },
          },
          {
            key: 'payment',
            label: 'ec.data.payment',
            type: 'text',
            component: () => import('modules/order/views/orderList/payment.vue'),
          },
          {
            key: 'delivery',
            label: 'ec.data.delivery',
            type: 'text',
            component: () => import('modules/order/views/orderList/delivery.vue'),
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.id,
          confirmIdentity: true,
        },
      },
      metaAction: {
        exportItemExcel: {
          component: () => import('./orderList/exportItemExcel.vue'),
        },
        createOrder: {
          label: 'order.action.create',
          callback: () => {
            this.vm?.$apopup.base({
              title: this.vm.$t('order.action.create'),
              bodySlot: () => import('modules/order/views/orderList/createOrderPopup.vue'),
              disabledApply: data => {
                if(!data) return true
                if(data.type === 'member') return !data.member_id
                return false
              },
              applyCallback: data => {
                const route = {
                  name: 'order-create',
                  query: {}
                }
                if(data.type === 'member') {
                  route.query = { member: data.member_id }
                }
                this.vm?.$router.push(route)
              },
            })
          },
        },
      },
      dataAction: {
        print: {
          label: 'ec.action.delivery-print',
          color: 'green',
          callback: row => {
            this.vm?.$apopup.base({
              title: this.vm.$t('ec.action.delivery-print'),
              applyCallback: () => {
                const route = {
                  name: 'ecpay-delivery-print',
                  params: { id: row.id },
                }
                const url = this.vm?.$router.resolve(route).href
                window.open(url)
              }
            })
          },
          create: (row) => {
            if(!row.delivery) return false
            return !!row.delivery.service_order_id
          },
          buttonProperties: () => ({
            class: 'white--text',
          }),
        },
        update: {
          label: 'action.edit',
          route: row => ({ name: 'order-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          removeTarget: row => row.name,
          confirmIdentity: true,
        },
      },
    }

  }
}

export default new listConfig()
