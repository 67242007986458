import {ExportExcelInterface} from "components/list/types/list";

export default (vm: Vue) => <ExportExcelInterface>{
  filename: (time: string) => `${vm?.$t('module.order')}`,
  sheets: [
    {
      label: 'module.order',
      data: {
        id: { label: 'order.data.id', width: 20 },
        created_at: { label: 'data.created_at', width: 18 },
        amount: { label: 'order.data.amount', width: 10 },
        delivery_fee: { label: 'ec.data.delivery_fee', width: 10 },
        amount_payable: { label: 'order.data.amount_payable', width: 10 },
        status: {
          label: 'order.data.status',
          width: 12,
          value: row => vm?.$t(`order.data.status.${row.status}`),
        },
        payment_type: {
          label: 'ec.data.payment_type',
          width: 12,
          value: row => vm?.$t(`ec.data.payment_type.${row.payment.type}`),
        },
        payment_status: {
          label: 'ec.data.payment_status',
          width: 12,
          value: row => vm?.$t(`ec.data.payment_status.${row.payment_status}`),
        },
        delivery_type: {
          label: 'ec.data.delivery_type',
          width: 12,
          value: row => vm?.$t(`ec.data.delivery_type.${row.delivery.type}`),
        },
        delivery_status: {
          label: 'ec.data.delivery_status',
          width: 20,
          value: row => vm?.$t(`ec.data.delivery_status.${row.delivery.status}`),
        },
        name: { label: 'data.person_name', width: 18 },
        email: { label: 'data.email', width: 26 },
        phone: { label: 'data.phone', width: 22 },
      },
    },
  ]
}
