<template>
  <eagle-list
    :list-key="listKey"
  ></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './orderListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'order-list',
    meta: {},
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.orderApi.index(params)
    },
    async deleteApi(target) {
      return await this.$api.collection.orderApi.delete(target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.orderApi.batch(targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.orderApi.batchDelete(targets)
    },
    getListConfig() {
      return listConfig
    },
  },
  computed: {
    feature() {
      return this.$store.getters['base/feature']
    },
    allPaymentTypes() {
      return this.feature.allPaymentTypes
    },
    allDeliveryTypes() {
      return this.feature.allDeliveryTypes
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
